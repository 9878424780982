<template>
  <div class="py-12 px-120px bg-white mdmax:px-4">
    <div class="container flex flex-col gap-12 mdmax:gap-7">
      <div class="text-3xl mdmax:text-2xl font-bold text-center">{{ $t('installment.options.title') }}</div>
      <div class="items-center justify-center hidden mdmax:flex"><img src="@/assets/images/logos/danacita.png" class="h-4" /></div>
      <div class="rounded-lg bg-white filter shadow-small">
        <div class="grid content-center items-center grid-cols-6 md:grid-cols-8 bg-grey-lighter">
          <div class="col-span-2 p-4 flex items-center mdmax:hidden"><img src="@/assets/images/logos/danacita.png" class="h-4" /></div>
          <div class="col-span-3 p-4 flex justify-center font-bold text-center text-sm md:text-base">{{ $t('installment.options.firstHeaderTable') }}</div>
          <div class="col-span-3 p-4 flex justify-center font-bold text-center text-sm md:text-base">
            Study Now,<br class="hidden mdmax:block" />
            Pay Later
          </div>
        </div>

        <div class="px-6">
          <div v-for="(listOption, index) in installOption" :key="listOption.title" :class="`${index === installOption.length - 1 ? '' : 'border-b'}`">
            <div class="grid grid-cols-6 md:grid-cols-8">
              <div :class="`${Array.isArray(listOption.item1) || Array.isArray(listOption.item2) ? 'items-start' : 'items-center'} col-span-2 py-4 flex text-sm font-bold mdmax:hidden`">
                {{ listOption.title }}
              </div>
              <div class="col-span-3 px-6 mdmax:px-2 py-4 flex text-xs md:text-sm flex-col font-medium text-center">
                <div class="font-bold hidden mdmax:block">{{ listOption.title }}</div>
                <div v-if="listOption.title !== 'Program'">{{ listOption.item1 }}</div>
                <div v-else v-for="(item, index) in listOption.item1" :key="index" class="spesial">
                  <li>{{ item }}</li>
                </div>
              </div>
              <div class="col-span-3 p-4 mdmax:px-2 flex text-xs md:text-sm flex-col font-medium text-center">
                <div class="font-bold hidden mdmax:block">{{ listOption.title }}</div>
                <div v-if="listOption.title !== 'Program'">{{ listOption.item2 }}</div>
                <div v-else v-for="(item, index) in listOption.item2" :key="index" class="spesial">
                  <li>{{ item }}</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center gap-6 mdmax:flex-col mdmax:gap-4">
        <p class="text-sm">{{ $t('installment.options.desc') }}</p>
        <Button :buttonText="`${$t('installment.options.cta')}`" type="tertiary" @action="downloadKit" additionalClass="w-full md:w-auto rounded py-3"></Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'installment',

  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },

  computed: {
    activeLanguange() {
      return this.$i18n.locale
    },
    installOption() {
      return this.$i18n.messages[this.activeLanguange].installment.options.tableItems
    }
  },
  methods: {
    downloadKit() {
      window.open('https://g2labcms.s3-ap-southeast-1.amazonaws.com/1674202375467-20221110_G2_Academy_Danacita_-_Admission_Kit.pdf')
    }
  }
}
</script>

<style lang="scss" scoped></style>
